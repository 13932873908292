/** @jsx jsx */
import { jsx } from '@emotion/core'
//import cmsUtils from '../../cmsUtils';
import utils from '../../../../utils';
import style from './simpleKakaduMapStyle';

export function SimpleKakaduMap(props){
  const item = props.item;
  //const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'SimpleKakaduMap', item.cssClass || item.anchorName || '');

  return(  
    <div className={cssClass} css={style.mapContainer}>
      <a href="/alliance-members/bawinanga-aboriginal-corporation">
        <div css={style.mapContainerDiv} style={{padding:'1% 14%',top:'13.5%',left:'55%'}}></div>
      </a>
      <a href="/alliance-members/gundjeihmi-aboriginal-corporation">
        <div css={style.mapContainerDiv} style={{padding:'1% 14%',top:'23.5%',left:'50%'}}></div>
      </a>
      <a href="/alliance-members/thamarrurr-plums">
        <div css={style.mapContainerDiv} style={{padding:'1% 15%',top:'29.4%',left:'41%'}}></div>
      </a>
      <a href="/alliance-members/thamarrurr-plums">
        <div css={style.mapContainerDiv} style={{padding:'1% 17%',top:'34.5%',left:'41%'}}></div>
      </a>
      <a href="/alliance-members/lombadina-aboriginal-corporation">
        <div css={style.mapContainerDiv} style={{padding:'1% 14%',top:'44.2%',left:'10%'}}></div>
      </a>
      <a href="/alliance-members/mercedes-cove-aboriginal-corporation">
        <div css={style.mapContainerDiv} style={{padding:'0.9% 15%',top:'51.7%',left:'10%'}}></div>
      </a>
      <a href="/alliance-members/mayi-harvests">
        <div css={style.mapContainerDiv} style={{padding:'1% 16.5%',top:'57%',left:'15%'}}></div>
      </a>
      <a href="/alliance-members/mamabulanjin-aboriginal-corporation">
        <div css={style.mapContainerDiv} style={{padding:'1% 14.5%',top:'65%',left:'11%'}}></div>
      </a>
      <img alt="map" src="/sites/naakpa/media/miscellaneous/naapka-map-v6.png" />
    </div>
  )
}