const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"mr-eaves-xl-modern",sans-serif',
  familyHeader: '"mr-eaves-xl-modern",serif',

  primaryColor: '#587234',
  fontColor: '#fff',
  bgColor: '#ebebeb',
  yellowColor : '#c0c032',
  greenColor : '#587234',
  greenColor2 : '#dee271',
  greenColor3 : '#809e57',
  darkGrey : '#221f20',
  ...overloading
}
export default variables;