import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'transparent',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    top : '0px'
  })),
  topScrolled: css(mq({
    background: 'black',
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: ['60px', null, '100px'],
    marginTop: ['2.5rem', null, '3rem'],
    transition:'all .2s'
  })),
  logoScrolled: css(mq({
    img : css(mq({
      height: [34, null, 50],
      marginTop:'0rem'
    }))
  }))
}