/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './orderDetailsStyle';
import React, {Fragment, useLayoutEffect} from 'react';
import env from '../../../env';
import { useGet } from '../../../components';
import { Loading, ErrorPopup } from '../../../components';
import { useRouter } from '../../../components';
import utils from '../../../utils';
import { useStore } from '../../../store';
import { ViewPartial } from '../../../cms/pages/partials/viewPartial';
import { gtm } from '../../../lib/tracking';
import { sendPurchaseEvent } from '../gtm';

export function OrderDetails() {
  const get = useGet();
  const { query, pathname } = useRouter();
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const o = query.o || '';

  React.useEffect(() => {
    get.send(env.apiBase + '/api/order/getPaidOrder/' + o);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }
  
  const order = get.response;
  if (!pageLoaded && order) {
    setPageLoaded(true);
    if (!query.noGTM) gtm.pageView(pathname, 'Order Thankyou');
    if (!query.noGTM) sendPurchaseEvent(order);
    return null;
  }
  return <Details order={order} />
}

function Details({ order }) {
  const orderItems = order.orderItems.filter(x => x.orderItemType === 0);
  //const taxLines = order.orderItems.filter(x => x.orderItemType === 1);
  const { dispatch } = useStore();

  useLayoutEffect(() => {
    dispatch({type:'SET_STATE', payload : {scrolled : true}});
    // eslint-disable-next-line
  }, []);

  function itemAmount(orderItem) {
    return (orderItem.unitPrice * 100 + (orderItem.addedUnitPrice ? orderItem.addedUnitPrice * 100 : 0) - (orderItem.discountUnitPrice ? orderItem.discountUnitPrice * 100 : 0)) / 100;
  }
  // function itemTotalAmount(orderItem) {
  //   return (orderItem.quantity * (orderItem.unitPrice * 100 + orderItem.addedUnitPrice * 100 - orderItem.discountUnitPrice * 100)) / 100;
  // }

  return <Fragment>
  <div css={style.orderDetails}>
      <hr />
      <div className="row" style={{marginLeft:'0px', marginRight:'0px'}}> 
        <div className="col-md-12">
          <div><label css={style.fn}>Order Number:</label> <span css={style.fv}>{order.orderNo}</span> </div>
          <div><label css={style.fn}>Total Amount:</label> <span css={style.fv}><b>AU {utils.money.formatMoney(order.totalAmount)}</b></span> </div>
          <div><label css={style.fn}>Order Date:</label> <span css={style.fv}>{utils.date.formatYYYYMMDD(order.orderDate)}</span> </div>
        </div>
      </div>
      <hr />

      <h3>ITEMS</h3>
      <div css={style.orderItems}>
        {orderItems.map((orderItem, i) => <div css={style.orderItem} className="row" key={orderItem.orderItemId}>
          <div className="col-6">
            <b dangerouslySetInnerHTML={{ __html: orderItem.itemName }}></b>
            <div dangerouslySetInnerHTML={{ __html: orderItem.itemDescHtml }}></div>
          </div>
          <div className="col-3">
            <div className="price">Unit Price: {utils.money.formatMoney(itemAmount(orderItem))}</div>
          </div>
          <div className="col-3">
            Qty: {orderItem.quantity}
          </div>
        </div>
        )}

        {
          (order.shippingAmount && order.shippingAmount > 0) ?
          <div css={style.orderItem} className="row">
            <div className="col-9">
              Shipping Fee: 
            </div>
            <div className="col-3">
              <div css={style.amount}>
                <b>{utils.money.formatMoney(order.shippingAmount)}</b>
              </div>
            </div>
        </div> : null
        }

        <div css={style.orderItem} className="row">
            <div className="col-12">
              GST: GST included in price
            </div>
          </div>

        {/* {taxLines.map((orderItem, index) => 
          <div key={orderItem.orderItemId} css={style.orderItem} className="row">
            <div className="col-9">
              Tax: {orderItem.itemName}
            </div>
            <div className="col-3">
              <div css={style.amount}>
                <b>{utils.money.formatMoney(orderItem.unitPrice)}</b>
              </div>
            </div>
          </div>
        )} */}

        <div className="row" style={{marginLeft:'0px', marginRight:'0px'}}>
          <div className="col-12">
            <div css={style.total}>
              Total: AU {utils.money.formatMoney(order.totalAmount)}
              {/* <span className="total-price">Item Total: {utils.money.formatMoney(order.itemAmount)}
                <span> + Shipping Fee: {order.shippingAmount > 0 ? 'AU ' + utils.money.formatMoney(order.shippingAmount) : 'FREE'}</span>
                <span> + GST: {utils.money.formatMoney(order.taxAmount)}</span>
              </span>
              <div className="grand-total">Grand Total (including GST): AU {utils.money.formatMoney(order.totalAmount)}
              </div> */}
            </div>
          </div>
        </div>

      </div>

      <hr />
      {/* <h3>CONTACT</h3> */}
      <h3>
              DELIVERY METHOD
      </h3>
      <div>
              {order.shippingMethod}
      </div>
      {
        (order.shippingAddress && order.shippingMethod === 'Standard') &&
        <Address addr={order.shippingAddress} />
      }
      {/* <Billing billingAddr = {order.billingAddress} useSameAddress = {order.useSameAddress} /> */}
    </div>
    <ViewPartial />
    </Fragment>
}

function Address({addr}) {
  return <div>
    <hr />
    <h3> SHIPPING ADDRESS </h3>
    <div><label css={style.fn}>First Name:</label> <span css={style.fv}>{addr.firstName}</span> </div>
    <div><label css={style.fn}>Surame:</label> <span css={style.fv}>{addr.surname}</span> </div>
    <div>
      <label css={style.fn}>Address:</label>
      <span css={style.fv}>{addr.street1}</span>
      <span css={style.fv} style={{marginLeft: '0.5rem'}}>{addr.street2}</span>
    </div>
    <div><label css={style.fn}>Suburb:</label> <span css={style.fv}>{addr.suburb}</span> </div>
    <div><label css={style.fn}>State:</label> <span css={style.fv}>{addr.state}</span> </div>
    <div><label css={style.fn}>Postcode:</label> <span css={style.fv}>{addr.postcode}</span> </div>
    <div><label css={style.fn}>Email Address:</label> <span css={style.fv}>{addr.email}</span> </div>
    {/* <div><label css={style.fn}>Mobile:</label> <span css={style.fv}>{addr.mobile}</span> </div> */}
  </div>
}

// function Billing(props){
//   const billingAddr = props.billingAddr;
//   const useSameAddress = props.useSameAddress
//   return (
//     <div>
//         <hr />
//         <h3> BILLING ADDRESS </h3>
//         {
//           useSameAddress === true && 
//           <div> Use the same address </div>
//         }
//         {
//           (billingAddr && useSameAddress === false) &&
//           <Fragment>
//             <div><label css={style.fn}>First Name:</label> <span css={style.fv}>{billingAddr.firstName}</span> </div>
//             <div><label css={style.fn}>Surame:</label> <span css={style.fv}>{billingAddr.surname}</span> </div>
//             <div>
//               <label css={style.fn}>Address:</label> 
//               <span css={style.fv}> {billingAddr.street1} </span>
//               <span css={style.fv} style={{marginLeft: '0.5rem'}}> {billingAddr.street2} </span>
//             </div>
//             <div><label css={style.fn}>Suburb:</label> <span css={style.fv}>{billingAddr.suburb}</span> </div>
//             <div><label css={style.fn}>State:</label> <span css={style.fv}>{billingAddr.state}</span> </div>
//             <div><label css={style.fn}>Postcode:</label> <span css={style.fv}>{billingAddr.postcode}</span> </div>
//             <div><label css={style.fn}>Email Address:</label> <span css={style.fv}>{billingAddr.email}</span> </div>
//             <div><label css={style.fn}>Mobile:</label> <span css={style.fv}>{billingAddr.mobile}</span> </div>
//           </Fragment>
//         }
//     </div>
//   )
// }