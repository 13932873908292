import { gtm } from '../../lib/tracking';

export function alreadySent(order) {
  const orderId = localStorage.getItem('orderIDSentToGTM');
  if (orderId && orderId === order.orderId.toString()) return true;
  localStorage.setItem('orderIDSentToGTM', order.orderId);
  return false;
}

export function sendPurchaseEvent(order) {
  if (!gtm.hasGTM()) return;

  if (alreadySent(order)) return;

  // for the details of the fields, https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
  const ecm = {
    purchase: {
      actionField: {
        id: order.orderId, // Transaction ID. Required for purchases and refunds.
        affiliation: 'Online Store',
        revenue: order.totalAmount, // Total transaction value (incl. tax and shipping)
        tax: order.taxAmount,
        shipping: order.shippingAmount,
        coupon: order.promoCode,
      },
      products: [],
    },
  };

  order.orderItems
    .filter((oi) => oi.orderItemType === 0)
    .forEach((oi) => {
      ecm.purchase.products.push({
        name: oi.itemName, // Name or ID is required.
        id: oi.productId,
        price: oi.unitPrice,
        quantity: oi.quantity,
      });
    });

  console.log(ecm);
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: ecm,
  });
}