const settings = {
  //                         xs,    sm,    md,    lg,    xl,    xxl          <-- only sample, check your breakpoints settings.
  //                         <      576    768    992    1200   1600
  breakpointNames:          ['xs', 'sm',  'md',  'lg',  'xl',  'xxl'],
  numOfTilesPerBreakpoints: [1,     2,     2,     2,     3,     3],
  marginLR:                 [14,    20,    20,    20,    20,    20],
  marginTB:                 [14,    20,    20,    20,    20,    20],
  slickSettings: {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    //fade: false,
    autoplay: true,
    //autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  }
}

exports.settings = settings