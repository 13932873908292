/** @jsx jsx */
import { jsx } from '@emotion/core'
//import style from './payStyle';
//import React from 'react';
//import { SiteLink, usePost } from '../../../components';
//import { Button, ErrorMessage } from '../../../components';
import env from '../../../env';

export function Pay({ cart, model, form, payStripe }){
  return(
    <div>
      <form action={`${env.apiBase}/api/cartcheckout/stripecheckout`} method="POST">
      <button type="submit">
        Checkout
      </button>
    </form>
    </div>
  )
}